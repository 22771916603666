import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { map } from 'rxjs/operators';
import { PageListingResponse } from '@models/api/page-listing-response.model';
import { EventTemplate } from '@models/design-templates/event-template.model';
import { EventTemplateCreationRequest } from '@models/design-templates/dto/event-template-creation.request';
import { EventTemplateAccessRequest } from '@models/design-templates/dto/event-template-access.request';
import { EventTemplateCardData } from '@models/design-templates/event-template-card-data.model';
import { EventTemplateAsset } from '@models/events/event-template-asset.model';
import { EventTemplateAssetSaveRequest } from '@models/events/dto/event-template-asset-save.request';
import { EventTemplateAssetType } from '@models/events/event-template-asset-type.enum';
import { EventTemplateCreationLangRequest } from '@models/design-templates/dto/event-template-creation-lang.request';

@Injectable()
export class EventTemplateService {
  constructor(private api: ApiService) {}

  getPaged(
    filters: Map<string, string>,
    pageNumber: number = 1,
    pageSize: number = 10,
  ): Observable<PageListingResponse<EventTemplate>> {
    return this.api
      .getPaged(
        `${env.api}/event-templates/paged`,
        pageNumber,
        pageSize,
        filters,
      )
      .pipe(map((it) => this.mapPaginated(it)));
  }

  private mapPaginated(res: PageListingResponse<EventTemplate>) {
    return {
      total: res.total,
      records: res.records.map((it) => new EventTemplate(it)),
    };
  }

  update(
    id: number,
    body: EventTemplateCreationLangRequest,
  ): Observable<EventTemplate> {
    return this.api
      .put(`${env.api}/event-templates/update/${id}`, body)
      .pipe(map((it) => new EventTemplate(it)));
  }

  delete(id: number): Observable<void> {
    return this.api.delete(`${env.api}/event-templates/delete/${id}`);
  }

  getAll(filters: Map<string, any>): Observable<EventTemplate[]> {
    if (!filters.has('forFiltering')) filters.set('forFiltering', true);
    return this.api
      .get(`${env.api}/event-templates`, filters)
      .pipe(map((it) => this.mapEventTemplates(it)));
  }

  private mapEventTemplates(res: EventTemplate[]) {
    return res?.map((it) => new EventTemplate(it));
  }

  fetch(
    searchTerm?: string,
    eventCategoryIds?: number[],
    targetGroupIds?: number[],
  ): Observable<EventTemplateCardData[]> {
    const params = new Map<string, any>();
    if (searchTerm && searchTerm !== '') {
      params.set('searchTerm', searchTerm);
    }
    if (eventCategoryIds) {
      params.set('eventCategoryIds', eventCategoryIds);
    }
    if (targetGroupIds) {
      params.set('targetGroupIds', targetGroupIds);
    }

    const userLang = localStorage.getItem('userLang');
    if (userLang) {
      params.set('lang', userLang);
    }

    return this.api.get(`${env.api}/event-templates/fetch`, params);
  }

  create(object: EventTemplateCreationLangRequest): Observable<EventTemplate> {
    return this.api
      .post(`${env.api}/event-templates/create`, object)
      .pipe(map((it) => new EventTemplate(it)));
  }

  get(id: number, currentLang?: string): Observable<EventTemplate> {
    let queryParams = new Map<string, any>();
    if (currentLang && currentLang !== '') {
      queryParams.set('lang', currentLang);
    }

    return this.api
      .get(`${env.api}/event-templates/${id}`, queryParams)
      .pipe(map((it) => new EventTemplate(it)));
  }

  getByUri(uri: string): Observable<EventTemplate> {
    return this.api
      .get(`${env.api}/event-templates/uri/${uri}`)
      .pipe(map((it) => new EventTemplate(it)));
  }

  duplicate(id: number): Observable<EventTemplate> {
    return this.api
      .post(`${env.api}/event-templates/${id}/duplicate`, {})
      .pipe(map((it) => new EventTemplate(it)));
  }

  getEventEffects(
    searchTerm?: string,
    eventCategoryId?: number,
  ): Observable<EventTemplateAsset[]> {
    const params = new Map<string, any>();
    if (searchTerm && searchTerm !== '') {
      params.set('searchTerm', searchTerm);
    }
    if (eventCategoryId) {
      params.set('eventCategoryId', eventCategoryId);
    }

    return this.api
      .get(`${env.api}/event-templates/effects`, params)
      .pipe(map((it) => this.mapEventTemplateAssets(it)));
  }

  getEventBackgrounds(
    searchTerm?: string,
    eventCategoryId?: number,
  ): Observable<EventTemplateAsset[]> {
    const params = new Map<string, any>();
    if (searchTerm && searchTerm !== '') {
      params.set('searchTerm', searchTerm);
    }
    if (eventCategoryId) {
      params.set('eventCategoryId', eventCategoryId);
    }

    return this.api
      .get(`${env.api}/event-templates/backgrounds`, params)
      .pipe(map((it) => this.mapEventTemplateAssets(it)));
  }

  getAssets(
    type?: EventTemplateAssetType,
    searchTerm?: string,
    eventCategoryId?: number,
  ): Observable<EventTemplateAsset[]> {
    const params = new Map<string, any>();
    if (type) {
      params.set('type', type);
    }
    if (searchTerm && searchTerm !== '') {
      params.set('searchTerm', searchTerm);
    }
    if (eventCategoryId) {
      params.set('eventCategoryId', eventCategoryId);
    }

    return this.api
      .get(`${env.api}/event-templates/assets`, params)
      .pipe(map((it) => this.mapEventTemplateAssets(it)));
  }

  private mapEventTemplateAssets(res: EventTemplateAsset[]) {
    return res.map((it) => new EventTemplateAsset(it));
  }

  getAssetsPaged(
    filters: Map<string, string>,
    pageNumber: number = 1,
    pageSize: number = 10,
  ): Observable<PageListingResponse<EventTemplateAsset>> {
    return this.api
      .getPaged(
        `${env.api}/event-templates/assets/paged`,
        pageNumber,
        pageSize,
        filters,
      )
      .pipe(map((it) => this.mapPaginatedAssets(it)));
  }

  private mapPaginatedAssets(res: PageListingResponse<EventTemplateAsset>) {
    return {
      total: res.total,
      records: res.records.map((it) => new EventTemplateAsset(it)),
    };
  }

  saveAsset(
    object: EventTemplateAssetSaveRequest,
  ): Observable<EventTemplateAsset> {
    return this.api
      .post(`${env.api}/event-templates/assets`, object)
      .pipe(map((it) => new EventTemplateAsset(it)));
  }

  deleteAsset(id: number): Observable<void> {
    return this.api.delete(`${env.api}/event-templates/assets/${id}`);
  }

  setAccess(
    id: number,
    dto: EventTemplateAccessRequest,
  ): Observable<EventTemplate> {
    return this.api
      .put(`${env.api}/event-templates/${id}/set-access`, dto)
      .pipe(map((it) => new EventTemplate(it)));
  }
}
